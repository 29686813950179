.patientInfoFieldName {
    font-weight: bold;
}

.patientInfoText {
    font-size: 14pt;
}

.patientInfoMenuButton {
    width: 100%;
}

.detailedPatientInfoGrid {
    margin-top: 4px !important;
}

.detailedPatientInfoFieldRow {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}